import { Drawer, Menu, Select } from 'antd';
import { FolderOpenOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { takeWhile } from 'lodash';
import { memo, useContext, useEffect, useMemo, useCallback, useState } from 'react';
import breadcrumbFor from './breadcrumbFor';
import ProgramsMenu from './ProgramsMenu';
import ProgramMenu from './ProgramMenu';
import StatesMenu from './StatesMenu';
import StateMenu from './StateMenu';
import DistrictMenu from './DistrictMenu';
import SchoolMenu from './SchoolMenu';
import ClassMenu from './ClassMenu';
import CustomMenu from './CustomMenu';
import { useReportsAccess } from '../../../lib/hooks';
import UiContext from '../../../lib/UiContext';
import styles from './index.module.scss';
const generateBreadcrumbs = (menu, {
  entity,
  otherParams
}) => {
  const _default = menu && [menu.topLevel];
  if (_default && entity) {
    const generateEntityBreadcrumbs = (entity, upto) => {
      if (entity) {
        const item = breadcrumbFor(entity);
        switch (entity.__typename) {
          case 'Class':
            return [...generateEntityBreadcrumbs(entity.school, upto), item];
          case 'School':
            return [...generateEntityBreadcrumbs(entity.district, upto), item];
          case 'District':
            return [...generateEntityBreadcrumbs(entity[upto]), item];
          case 'State':
            return [item];
          case 'ProgramGroup':
            return [item];
        }
      }
      return [];
    };
    const mergeBreadcrumbs = (menu, entityBreadcrumbs) => {
      const index = ['Programs', 'States'].includes(menu.at(-1).type) ? entityBreadcrumbs.findIndex(({
        type
      }) => `${type}s` === menu.at(-1).type) : (r => r < 0 ? r : r + 1)(entityBreadcrumbs.findIndex(({
        id,
        type
      }) => menu.at(-1).type === type && menu.at(-1).id === id));
      const breadcrumbs = index < 0 ? null : menu.concat(entityBreadcrumbs.slice(index));
      const options = [breadcrumbs, ...(menu.at(-1).submenus?.map(item => mergeBreadcrumbs([...menu, item], entityBreadcrumbs)) ?? [])].filter(Boolean);
      return options.length <= 1 ? options[0] : options.reduce((current, next) => current.length < next.length ? next : current);
    };
    const checkEntity = entity.__typename !== 'User' ? entity : (entity.enrollments?.find(e => e.class.type && e.class.id === otherParams.classId) ?? entity.enrollments?.filter(e => ['HOMEROOM', null].includes(e.class.type)).find(e => dayjs().isBetween(e.from, e.to, 'day', '[]')))?.class;
    const a = mergeBreadcrumbs(_default, generateEntityBreadcrumbs(checkEntity, 'programGroup'));
    const b = mergeBreadcrumbs(_default, generateEntityBreadcrumbs(checkEntity, 'state'));
    if (a) {
      if (b) {
        return a.length < b.length ? a : b;
      }
      return a;
    }
    return b ?? _default;
  }
  return _default;
};
const SideNavigation = ({
  isOpen,
  setIsOpen
}) => {
  const menuConfig = useReportsAccess().menu;
  const uiContext = useContext(UiContext);
  const semesters = useMemo(() => uiContext.semesters?.filter(s => s.classCount > 0).sort((a, b) => a.order - b.order), [uiContext.semesters]);
  const [semester, updateSemester] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState();
  const updateBreadcrumbs = useCallback(e => setBreadcrumbs(arr => arr.slice(0, e.item.props.index + 1)), [setBreadcrumbs]);
  const addBreadcrumb = useCallback(e => {
    setBreadcrumbs(arr => [...arr, e]);
  }, [setBreadcrumbs]);
  const menu = useMemo(() => {
    const location = breadcrumbs?.at(-1);
    if (!location) {
      return;
    }
    const props = {
      semester: semester,
      setIsOpen: setIsOpen,
      updateState: addBreadcrumb
    };
    switch (location.type) {
      case 'Programs':
        return <ProgramsMenu {...props} />;
      case 'Program':
        return <ProgramMenu {...props} programId={location.id} />;
      case 'States':
        return <StatesMenu {...props} />;
      case 'State':
        return <StateMenu {...props} stateId={location.id} />;
      case 'District':
        return <DistrictMenu {...props} districtId={location.id} />;
      case 'School':
        return <SchoolMenu {...props} schoolId={location.id} />;
      case 'Class':
        return <ClassMenu {...props} classId={location.id} />;
      default:
        return <CustomMenu {...props} location={location} />;
    }
  }, [breadcrumbs, addBreadcrumb, semester, setIsOpen]);
  if (semester && breadcrumbs) {
    const newBreadcrumbs = takeWhile(breadcrumbs, e => !(e.semesters && !e.semesters.includes(semester)));
    if (newBreadcrumbs.length !== breadcrumbs.length) {
      setBreadcrumbs(newBreadcrumbs);
    }
  }
  if (menuConfig && semesters) {
    if (!semester) {
      const names = semesters.filter(e => Date.parse(e.earliestDate) <= Date.now()).map(e => e.name);
      const name = names.includes(uiContext.otherParams.semester) ? uiContext.otherParams.semester : names.includes(uiContext.entity?.semester) ? uiContext.entity?.semester : semesters.filter(s => menuConfig.semesters?.includes(s.name))[0]?.name ?? names[0];
      updateSemester(name);
    }
  } else {
    updateSemester();
  }
  useEffect(() => {
    setIsOpen(menuConfig ? !!isOpen : null);
    setBreadcrumbs(generateBreadcrumbs(menuConfig, uiContext));
  }, [uiContext, menuConfig, isOpen, setIsOpen, setBreadcrumbs]);
  return <Drawer placement="left" closable={false} bodyStyle={{
    padding: 0
  }} headerStyle={{
    padding: 0
  }} onClose={() => setIsOpen(false)} open={isOpen} width={360} className={styles.drawer} data-sentry-element="Drawer" data-sentry-component="SideNavigation" data-sentry-source-file="index.jsx">
      <div className={styles["nav-ancestry"]}>
        <div className={styles["semester-selector"]}>
          <Select value={semester} onSelect={updateSemester} children={semesters.map(({
          name
        }) => <Select.Option key={name} value={name} children={name} />)} data-sentry-element="Select" data-sentry-source-file="index.jsx" />
        </div>
        <Menu className={styles.breadcrumb} selectable={false} onClick={updateBreadcrumbs} items={breadcrumbs?.map((item, index, arr) => ({
        key: `${item.type}-${item.id}`,
        index: index,
        className: styles[arr.length - 1 === index ? 'last-breadcrumb-link' : 'breadcrumb-link'],
        disabled: arr.length - 1 === index,
        label: <div style={{
          marginLeft: index * 8
        }}>
                {item.icon ?? <FolderOpenOutlined />} {item.name}
              </div>
      }))} data-sentry-element="Menu" data-sentry-source-file="index.jsx" />
      </div>
      {menu}
    </Drawer>;
};
export default memo(SideNavigation);