import { Badge, Button, List, Modal, Tabs, Tag } from 'antd';
import React, { useState } from 'react';
import cmsFactory from '../cmsFactory';
import { formatDatetime } from '../../helpers/datetime';
import htmlStringToReactNodes from '../../helpers/htmlStringToReactNodes';
const ReleaseNotes = ({
  data: {
    title,
    releaseNotesList
  },
  buttonTitle,
  buttonIcon
}) => {
  const [modalState, setModalState] = useState(false);
  const tabItems = [{
    label: "What's New",
    key: 'release notes',
    children: <List className="release-notes-list" itemLayout="vertical" size="large" pagination={{
      pageSize: 3,
      position: 'top',
      size: 'small'
    }} dataSource={releaseNotesList} renderItem={({
      attributes: {
        notes,
        title,
        releaseNumber,
        releaseDate,
        components
      }
    }) => {
      return <List.Item key={releaseNumber}>
                <List.Item.Meta title={<h4>{title}</h4>} /><div>{htmlStringToReactNodes(notes)}</div>
                <br />
                <div>
                  <em>Release Date: {formatDatetime(releaseDate)} </em><br />
                  {releaseNumber ? <em><span>Version {releaseNumber}</span></em> : null}
                </div>

                {components.length > 0 && <div className='components-list'>{components.map(tag => <Tag size="small" color='geekblue'>
                    {tag}
                  </Tag>)}</div>}
              </List.Item>;
    }} />
  }];
  return <>
      <Button type="link" shape="circle" size="large" icon={buttonIcon} onClick={() => setModalState(true)} data-sentry-element="Button" data-sentry-source-file="ReleaseNotes.jsx">
        {buttonTitle}
      </Button>
      <Modal title={<h3>{title}</h3>} centered bodyStyle={{
      height: '80vh',
      overflow: 'scroll'
    }} open={modalState} footer={null} onCancel={() => setModalState(false)} width={960} data-sentry-element="Modal" data-sentry-source-file="ReleaseNotes.jsx">
        <Tabs tabPosition="left" items={tabItems} data-sentry-element="Tabs" data-sentry-source-file="ReleaseNotes.jsx" />
      </Modal>
    </>;
};
export default cmsFactory(ReleaseNotes, '/content/singletons/releaseNotesList.json');